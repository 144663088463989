<template>
  <div>
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      :immediately="true"
      url="/organization/employee/page">
      <el-button
        v-if="$auth('员工管理新增')"
        slot="toolbar"
        type="primary"
        size="mini"
        @click="$refs.editPage.open()">
        {{$l("common.add", "新增")}}
      </el-button>

      <el-button
        v-if="$auth('员工管理导入')"
        slot="toolbar"
        :loading="loading"
        type="primary"
        size="mini"
        @click="visible=!visible">
        导入
      </el-button>
      <el-button
        v-if="$auth('员工管理导出')"
        slot="btn"
        :disabled="status==='loading'"
        size="mini"
        style="height: 26px;padding: 0 10px"
        type="primary"
        @click="exportExcelData">
        {{$l("common.search", "导出")}}
      </el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search label="员工名称">
            <el-input v-model="filter.name" type="iotDeviceModel"></el-input>
          </vm-search>
          <vm-search label="电话号码">
            <el-input v-model="filter.phone" type="iotDeviceModel"></el-input>
          </vm-search>
          <vm-search label="班组名称">
            <el-input v-model="filter.teamName" type="iotDeviceModel"></el-input>
          </vm-search>
        </div>
      </template>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="name" label="员工姓名" align="center"></el-table-column>
      <el-table-column prop="gender" label="性别" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.gender === 'male'">男</div>
          <div v-if="scope.row.gender === 'female'">女</div>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="电话号码" align="center"></el-table-column>
      <el-table-column prop="teamName" label="班组名称" align="center"></el-table-column>
      <el-table-column prop="leaderName" label="是否队长" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.leaderName">是</span>
          <span v-else>否</span>
        </template>
      </el-table-column>
      <el-table-column prop="roleName" label="用户角色" align="center">
        <template slot-scope="scope">
          {{scope.row.roles instanceof Array ? scope.row.roles.map(item=>{
            if (item.name){
              return item.name.replace('[','')

            }else {
              return ''
            }
          }):''}}
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注信息" align="center" width="90">
      </el-table-column>

      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate" width="130px">
        <template slot-scope="scope">
          <el-button v-if="$auth('员工管理编辑')" type="primary" @click="$refs.editPage.open(scope.row.id)">{{$l("common.edit", "编辑")}}</el-button>
          <el-button v-if="$auth('员工管理证书')" type="primary" @click="$refs.certificate.open(scope.row.id,scope.row.name)">{{$l("common.edit", "证书管理")}}</el-button>
          <el-button v-if="$auth('员工管理删除')&&!scope.row.leaderName" type="danger" @click="deleteRow(scope.row)">{{$l("common.delete", "删除")}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
    <certificate ref="certificate" @save-success="getList(-1)"></certificate>
    <el-dialog :visible.sync="visible" width="450px">
      <vm-drag-upload-file
        url="excelTemplate/user.xlsx"
        action="authentication/user/import"
        name="员工导入"
        download-name="员工模板.xlsx"
        @save-success="getList(-1)"></vm-drag-upload-file>
    </el-dialog>
  </div>
</template>

<script>
  import EditPage from "./EmployeeEdit.vue";
  import Certificate from "./certificate.vue";

  import {export2Excel} from "@/util";
  import moment from "moment";
  import loginUtil from "@/util/loginUtil";
  export default {
    components: {EditPage, Certificate},
    data() {
      return {
        loading:false,
        data: [],
        filter: {

        },
        defaultProps: {
          children: "children",
          label: "label",
        },
        dialogVisible:false,
        width:200,
        visible: false,
      };
    },
    computed:{
      formatData:function() {
        console.log(this.filter,11);
        return [];
      },
    },
    created() {
      this.filter.companyId = loginUtil.getCompanyCode();

    },
    mounted() {
      this.getList(1);
    },
    methods: {
      exportExcelData() {
        const title={
          name:"姓名",
          phone:"电话",
          teamName:"维保班组",
          // teamLeaderName:"队长",
        };
        this.$http.get("organization/employee/page",{...this.filter,pageSize:300000}).then(res=>{

          export2Excel(title,res.records,`班组成员-全部-${res.total}条-${moment(new Date()).format("YYYYMMDDhhmmss")}`);
        });
      },
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
        this.visible = false;
      },
      handleNodeClick(data) {
        console.log(data,89);
        this.filter={...this.filter,companyId:data.id};
        this.$refs.vmTable.getList(0,{companyId:data.id});
      },
      deleteRow(row) {
        this.$confirm(`确定删除${row.name}吗?`, this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete(`/organization/employee/${row.id}`).then(() => {
            this.getList(-1);
            this.$message.success(row.name + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },

    },
  };
</script>

<style scoped>
.treeBox{
  border: 1px solid #E6E6E6;
  padding: 10px;
  margin-right: 10px;
  width: calc(100% - 1200px);
  max-height: 750px;
  overflow-y: auto;
}
</style>
