<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?'编辑员工':'新增员工'"
    width="500px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="form"
      :label-width="$l('device.labelWidth','80px')">
      <el-form-item label="员工名称" prop="name">
        <el-input v-model="model.name" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="gender">
        <el-select v-model="model.gender" :placeholder="$l('common.enter','请输入')">
          <el-option label="男" value="male"></el-option>
          <el-option label="女" value="female"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$l('project.assigneeName','班组')" prop="assigneeId">
        <vm-autocomplete
          width="100%"
          :model.sync="model"
          :value-name="model.teamName"
          value-key="name"
          linkage-name="id"
          require-name="teamId"
          :value="{teamName:'name',teamId:'id'}"
          url="organization/team/list"></vm-autocomplete>
      </el-form-item>
      <el-form-item label="手机号" prop="phone" :rules="$rule.notNull">
        <el-input v-model.trim="model.phone" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
      <el-form-item label="备注信息" prop="remark">
        <el-input v-model="model.remark" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
      <el-form-item label="所属公司" prop="companyId">
        <el-select v-model="model.companyId" :placeholder="$l('common.enter','请输入')">
          <el-option
            v-for="item in data"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="用户角色" prop="roleIds">
        <el-select v-model="model.roles" multiple placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel","取消")}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save","保存")}}</el-button>
    </span>
  </el-dialog>
</template>
<script>

  import {getWidth} from "@/util";
  import VmAutocomplete from "@/components/VmAutocomplete";
  import loginUtil from "@/util/loginUtil";
  export default {
    components: {VmAutocomplete},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        model: {
          id: 0,
          no: "",
          source: "",
          isp: "",
          roles: [],
        },
        data: [],
        options: [],
        width:200,
      };

    },
    mounted() {

    },
    methods: {
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;
        this.getData();
        this.getTreeList();
        this.getRoles();
      },
      getData() {
        if (this.model.id) {
          this.contentLoading = true;
          this.$http.get(`/organization/employee/${this.model.id}`).then(data => {
            this.contentLoading = false;
            this.model = data;
            this.model.roles= data.roles.map(item=>item.id);
          });
        }
      },
      getRoles() {
        this.$http.get("/authentication/role/list").then(data => {
          this.options = data;
        });
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            const roles = this.model.roles.map(item => ({id: item}));
            this.$http
              .save("organization/employee", {...this.model,roles})
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },
      getTreeList() {
        let filter = this.filter;
        if (this.filterFun) {
          let filterCopy = JSON.parse(JSON.stringify(filter));
          filter = this.filterFun(filterCopy);
        }
        let http =  this.$http.get("/organization/company/page",{pageSize:100});
        http.then(data => {
          this.data = data.records.filter(item=>item.id===loginUtil.getCompanyCode()||item.parentId===loginUtil.getCompanyCode());
          this.width=100+14*getWidth(data);
        }).catch(() => {
          this.status = "error";
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
