<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?'编辑证书':'新增证书'"
    width="1200px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="form"
      :label-width="$l('device.labelWidth','80px')">
      <el-form-item label="员工名称" prop="name">
        <el-input v-model="name" :disabled="true" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="add">新增证书</el-button>
    <el-table :data="data" label-position="center">
      <el-table-column
        prop="date"
        header-align="center"
        label="类型"
        align="center"
        width="180">
        <template slot-scope="scope">
          <el-select
            v-if="scope.row.edit"
            v-model="scope.row.certsType"
            controls-position="right"
            :precision="0"
            style="width: 100%">
            <el-option value="operation_certs" label="操作证"></el-option>
            <el-option value="quality_inspector_certs" label="质量检测"></el-option>
          </el-select>
          <span v-if="!scope.row.edit">{{options[scope.row.certsType]}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="certsNo"
        align="center"

        label="编号"
        header-align="center"
        width="180">
        <template slot-scope="scope">
          <el-input
            v-if="scope.row.edit"
            v-model="scope.row.certsNo"

            style="width: 100%"></el-input>
          <span v-if="!scope.row.edit">{{scope.row.certsNo}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="startDate"
        align="center"

        header-align="center"
        label="开始时间">
        <template slot-scope="scope">
          <el-date-picker
            v-if="scope.row.edit"
            v-model="scope.row.startDate"
            controls-position="right"
            :precision="0"
            style="width: 100%"></el-date-picker>
          <span v-if="!scope.row.edit">{{scope.row.startDate?$moment(scope.row.startDate).format('YYYY-MM-DD'):''}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="effectiveDate"
        header-align="center"
        align="center"
        label="结束时间">
        <template slot-scope="scope">
          <el-date-picker
            v-if="scope.row.edit"
            v-model="scope.row.effectiveDate"
            controls-position="right"
            :precision="0"
            style="width: 100%"></el-date-picker>
          <span v-if="!scope.row.edit">{{scope.row.effectiveDate?$moment(scope.row.effectiveDate).format('YYYY-MM-DD'):''}}</span>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        prop="address"
        align="center"
        label="操作">
        <template slot-scope="scope">
          <el-button type="primary" @click="editRow(scope.row)">{{scope.row.edit?'保存':'编辑'}}</el-button>
          <el-button type="danger" @click="deleteRow(scope.row)">{{$l("common.delete", "删除")}}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel","取消")}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save","保存")}}</el-button>
    </span>
  </el-dialog>
</template>
<script>

  import moment from "moment";

  export default {
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        model: {
          id: 0,
          no: "",
          source: "",
          isp: "",
          roles: [],
        },
        data: [{}],
        options: {operation_certs:"操作证",quality_inspector_certs:"质量检测证"},
        edit:true,
        width:200,
        name:"",
      };

    },
    mounted() {

    },
    methods: {
      open(id,name) {
        this.model.id = id;
        this.name = name;
        this.dialogVisible = true;
        this.getData();
      },
      getData() {
        if (this.model.id) {
          this.contentLoading = true;
          this.$http.get("/basic/employee-certificate/list",{employeeId:this.model.id,
          }).then(data => {
            this.contentLoading = false;
            this.data = data.map(item=>{
              return {...item,startDate:item.startDate?moment(item.startDate):"",effectiveDate:item.effectiveDate?moment(item.effectiveDate):"",edit:false};
            });
          });
        }
      },
      add() {
        this.data.push({edit:true,employeeId:this.model.id});
      },
      editRow(item) {
        console.log(item,149);
        item.edit=!item.edit;
      },
      deleteRow(obj) {
        this.data = this.data.filter(item=>item!==obj);
      },

      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            const arr  = this.data.map(item=>{
              return {...item,startDate:moment(item.startDate).format("YYYY-MM-DD"),effectiveDate:moment(item.effectiveDate).format("YYYY-MM-DD")};
            });
            this.$http
              .save("/basic/employee-certificate/batch", arr)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },

    },
  };
</script>
<style lang="scss" scoped>
</style>
